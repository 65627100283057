import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const Hero = props => (
  <BackgroundImage
    Tag="div"
    className={`hero hero-large z-0 relative ${props.className ? props.className : ''}`}
    fluid={props.imagebg}
    style={props.style}
  >
    <div className="hero-body relative w-full block z-30">
      <div className="container flex">
        <div className="w-full md:w-2/3 lg:w-1/2 flex self-end text-white ">{props.title}</div>
      </div>
    </div>
    <div className={`${props.overlay} absolute pin z-10`} />
  </BackgroundImage>
);

export default Hero;
